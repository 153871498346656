@font-face {
  font-family: "Nunito Sans";
  src: local("NunitoSans-SemiBold"),
    url("./fonts/NunitoSans-SemiBold.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Nunito-Sans-Bold";
  src: local("NunitoSans-Bold"),
    url("./fonts/NunitoSans-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "NunitoSans-Light";
  src: local("NunitoSans-Light"),
    url("./fonts/NunitoSans-Light.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "NunitoSans-ExtraLight";
  src: local("NunitoSans-ExtraLight"),
    url("./fonts/NunitoSans-ExtraLight.ttf") format("truetype");
  font-weight: 400;
}

body {
  margin: 0;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
