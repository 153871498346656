.spinner-border {
    display: inline-block;
    width: 8rem;
    height: 8rem;
    vertical-align: -.125em;
    border: .5em dotted currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: .75s linear infinite spinner-border;
    animation: .75s linear infinite spinner-border;
}

.message {
    position: absolute;
    font-size: 25px;
}